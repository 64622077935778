import { useState, useEffect } from "react";
import axios from "axios";
import Userfront from "@userfront/react";
import { useParams } from "react-router-dom";
import CheckAuth from "../../components/AuthCheck";
import { host } from "../../App";
import "../create/Create.css";

const Edit = () => {
  const { id } = useParams();

  // States for the form
  const [title, setTitle] = useState("");
  const [type, setType] = useState("Baseball");
  const [stream, setStream] = useState("");
  const [numBreaks, setNumBreaks] = useState("");
  const [desc, setDesc] = useState("");
  const [a, setA] = useState({});

  useEffect(() => {
    axios
      .get(`${host}/breaks/get/byId?id=${id}`, {
        headers: { authorization: `Bearer ${Userfront.accessToken()}` },
      })
      .then((res) => {
        let data = res.data[0];
        setTitle(data.title);
        setType(data.type);
        setStream(data.stream);
        setNumBreaks(data.breakList);
        setDesc(data.description);
        setA(data);
        if (Userfront.user.username !== data.user) {
          // User is not correct so redirect them back home
          window.location.replace(`/breaks/${id}`);
        }
      });
  }, [id]);
  // Check if the user who is on this is the one who created the break

  const onSubmit = async (e) => {
    e.preventDefault();
    const body = {
      user: Userfront.user.username,
      type: type,
      stream: stream,
      breakList: numBreaks,
      description: desc,
      title: title,
      id: id,
    };
    const a = async () => {
      const res = await fetch(`${host}/breaks/edit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Userfront.accessToken()}`,
        },
        body: JSON.stringify(body),
      });
      console.log(res);
      window.location.replace("/home");
    };

    a();
  };

  return (
    <div className="createBreak">
      <CheckAuth location="/edit" />
      <div>
        <h1>Edit A Break</h1>
        <form name="login" onSubmit={onSubmit}>
          <label>
            Title:
            <br />
            <input
              className="create"
              type="text"
              placeholder="Title"
              defaultValue={title}
              onChange={(e) => setTitle(e.target.value)}
              maxlength="400"
              required
            />
          </label>
          <br />
          <label>
            Type:
            <br />
            <select
              onChange={(e) => setType(e.target.value)}
              defaultValue={type}
            >
              <option defaultValue="Baseball">Baseball</option>
              <option defaultValue="Football">Football</option>
              <option defaultValue="Basketball">Basketball</option>
              <option defaultValue="Pokemon">Pokemon</option>
              <option defaultValue="Other">Other</option>
            </select>
          </label>
          <br />
          <label>
            Stream
            <br />
            <input
              className="create"
              type="text"
              placeholder="Stream"
              defaultValue={stream}
              onChange={(e) => setStream(e.target.value)}
              maxlength="400"
              required
            />
          </label>
          <br />
          <label>
            Number Of Breaks:
            <br />
            <input
              type="number"
              placeholder="Number of Breaks"
              defaultValue={numBreaks}
              onChange={(e) => setNumBreaks(e.target.value)}
              min="1"
              required
            />
          </label>
          <br />
          <label>
            Description:
            <br />
            <textarea
              onChange={(e) => setDesc(e.target.value)}
              defaultValue={desc}
              maxlength="1000"
              rows="6"
              cols="50"
            />
          </label>
          <br />
          <input type="submit" defaultValue="Submit" className="create" />
        </form>
      </div>
    </div>
  );
};

export default Edit;
