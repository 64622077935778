import Userfront from "@userfront/react";
import { Redirect } from "react-router-dom";

const LandingPage = () => {
  return (
    <div className="landingPage" style={{ textAlign: "center" }}>
      {Userfront.accessToken() ? (
        <Redirect to={{ pathname: "/home", state: { from: "/" } }} />
      ) : (
        <></>
      )}
      <h1>Welcome to Cardbreaker!</h1>
      <p>
        The purpose of this is to give you a place to advertise your card breaks
      </p>
    </div>
  );
};

export default LandingPage;
