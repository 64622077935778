import CheckAuth from "../components/AuthCheck";
import Breaks from "../components/Breaks/DisplayAllBreaks";
import OthersBreaks from "../components/Breaks/DisplayOthersBreaks";
const Home = () => {
  // Fetch the current users breaks

  return (
    <div>
      <CheckAuth location="home" />
      <h1>Your Active Breaks</h1>
      <Breaks />
      <div className="container-fluid">
      <div className="row">
      <h1>All Breaks</h1>
      </div>
      </div>
      <div className="container-fluid">
      <OthersBreaks />
      </div>
    </div>
  );
};

export default Home;
