import { useState, useEffect } from "react";
import axios from "axios";
import Userfront from "@userfront/react";
import { host } from "../../App";
import CreateBreakLink from "./BreakLink";

const OthersBreaks = () => {
  const [a, setA] = useState([]);
  useEffect(() => {
    axios
      .get(`${host}/breaks/get/all?usr=${Userfront.user.username}`, {
        headers: { authorization: `Bearer ${Userfront.accessToken()}` },
      })
      .then((res) => {
        setA(res.data.breaks);
      })
      .catch((err) => {
        setA(JSON.stringify(err));
      });
  }, []);

  return <CreateBreakLink a={a} />;
};

export default OthersBreaks;
