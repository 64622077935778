import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Userfront from "@userfront/react";
import axios from "axios";
import { host } from "../App";

const Breaks = () => {
  let con = true;
  let desc;
  const [a, setA] = useState("")
  const [b, setB] = useState("a");

  const { id } = useParams();

  if (isNaN(id)) {
    window.location.replace("/404");
    con = false;
  }

  useEffect(() => {
    if (con === false) {
      // The id is a string and exit so it before the request so it doesn't break the API
      return;
    }
    axios
      .get(`${host}/breaks/get/byId?id=${id}`, {
        headers: { authorization: `Bearer ${Userfront.accessToken()}` },
      })
      .then((res) => {
        setA(res.data[0]);
       // desc = //a.description.replace('/n','<br />');
      }).catch((err) => {
        if(err){
          con = false;
          throw err;
          //window.location.replace("/404");
        }
      })
  }, [id]);

  return (
    <div className="DisplayBreakInfo" style={{ textAlign: "center" }}>
      <h3 style={{ textAlign: "center" }}>Title: {a.title}</h3>
      <h3>Type: {a.type}</h3>
      <p>
        {a.breakList} breaks are available for purchase from {a.user}
      </p>
      <p>
        {a.user} will be streaming it live at {a.stream}
      </p>
      <h3>Description</h3>
      <p>{a ? a.description.split ('\n'). map ((item, key) => { return <span key = {key}> {item} <br/> </span> }) : ''}</p>
      {a.user === Userfront.user.username ? (
        <a href={`/edit/${id}`} className="btn btn-primary btn">
          Edit
        </a>
      ) : (
        <> </>
      )}
    </div>
  );
};

export default Breaks;
