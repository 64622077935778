import { Card, Button } from "react-bootstrap";

const CreateBreakLink = ({ a }) => {
  return (
    <div classNameName="Breaks">
      {a.length === 0 ? (
        <p>There are no breaks to display at this time</p>
      ) : (
        <> </>
      )}
      {a.map((data) => (
        <>
          <div className="col-sm-6">
          <div className="card" style={{ width: "18rem" }}>
            <div className="card-body">
              <h5 className="card-title">{data.title}</h5>
              <p className="card-text">
                {data.description.substring(0,100)+'...'}
              </p>
              <a href={`/breaks/${data.id}`} className="btn btn-primary">
                Go to the Break
              </a>
            </div>
          </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default CreateBreakLink;
