const PageNotFound = () => {
  return (
    <div>
      <h1>Error 404</h1>
      <p>Page Not Found</p>
    </div>
  );
};

export default PageNotFound;
