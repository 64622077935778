import { useState } from "react";
import Userfront from "@userfront/react";

import "./Login.css";
import Alert from "../../components/Alert";

Userfront.init("5nxrwvn7");

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    setAlertMessage("");

    Userfront.login({
      method: "password",
      emailOrUsername: username,
      password: password,
    }).catch((error) => {
      setAlertMessage(error.message);
    });
  };
  return (
    <div className="login">
      <Alert alertMessage={alertMessage} />
      <div className="form">
        <h1>Log In</h1>
        <form name="login" onSubmit={onSubmit}>
          <label>
            Username or Email:
            <br />
            <input
              type="text"
              placeholder="Username or Email:"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </label>
          <label>
            Password:
            <br />
            <input
              type="password"
              placeholder="Password:"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>
          <br />
          <input type="submit" value="Login" />
        </form>

        <p>
          Not registered yet? <a href="register">Register Here</a>
        </p>
      </div>
    </div>
  );
};

export default Login;
