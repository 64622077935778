import { React, useState, useEffect } from "react";
import Userfront from "@userfront/react";

// import { useEffect } from 'react';
// import Axios from 'axios';
// import { useState } from 'react';

import Router from "./components/Router";
import Navbar from "./components/Navbar";
// import './App.css';

const host = process.env.REACT_APP_HOST;
const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (Userfront.accessToken()) {
      setLoggedIn(true);
    }
  }, []);

  return (
    <div className="App">
      <Navbar loggedIn={loggedIn} />
      <Router />
    </div>
  );
};
export { host };
export default App;
