const Alert = ({ alertMessage }) => {
  if (!alertMessage) return "";
  return (
    <div class="alert alert-danger">
      <p>
        {" "}
        <strong>Error!</strong> {alertMessage}{" "}
      </p>
    </div>
  );
};

export default Alert;
