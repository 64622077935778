import { useState } from "react";
import Userfront from "@userfront/core";

// File Imports
import "./Login.css";
import Alert from "../../components/Alert";

Userfront.init("5nxrwvn7");

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPass, setVerifyPass] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    setAlertMessage("");

    if (verifyPass !== password) {
      return setAlertMessage(`Passwords Don't Match`);
    }

    Userfront.signup({
      method: "password",
      username: username,
      password: password,
      email: email,
    }).catch((error) => {
      setAlertMessage(error.message);
    });

    // Userfront.signup({
    //   method: "password",
    //   email: this.state.email,
    //   password: this.state.password,
    // });
  };
  return (
    <div className="login" id="a">
      <Alert alertMessage={alertMessage} />
      <div className="form">
        <h1>Register</h1>

        <form name="login" onSubmit={onSubmit}>
          <label>
            Email:
            <br />
            <input
              type="text"
              placeholder="Email:"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>
          <label>
            Username:
            <br />
            <input
              type="text"
              placeholder="Username:"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </label>
          <label>
            Password:
            <br />
            <input
              type="password"
              placeholder="Password:"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>
          <label>
            Verify Password:
            <br />
            <input
              type="password"
              placeholder="Verify Password:"
              value={verifyPass}
              onChange={(e) => setVerifyPass(e.target.value)}
              required
            />
          </label>
          <br />
          <input type="submit" value="Register" />
        </form>

        <p>
          Already Registered? <a href="login">Login Here</a>
        </p>
      </div>
    </div>
  );
};

export default Register;
