import { Switch, Route } from "react-router-dom";
import Login from "../pages/auth/Login";
import Logout from "../pages/auth/Logout";
import Register from "../pages/auth/Register";
import Home from "../pages/Home";
import PageNotFound from "../pages/ErrorPages/404";
import LandingPage from "../pages/LandingPage";
import Breaks from "../pages/Breaks";
import Create from "../pages/create/Create";
import Edit from "../pages/edit/Edit";
import A from "../pages/fuckMe/A";

const Router = () => {
  return (
    <Switch>
      <Route exact path="/" component={LandingPage}></Route>
      <Route exact path="/home" component={Home}></Route>
      <Route exact path="/login" component={Login}></Route>
      <Route exact path="/register" component={Register}></Route>
      <Route exact path="/create" component={Create}></Route>
      <Route exact path="/logout" component={Logout}></Route>
      <Route path="/breaks/:id" children={<Breaks />}></Route>
      <Route path="/edit/:id" children={<Edit />}></Route>
      <Route path="/fuckMe" component={A}></Route>
      <Route path="*" component={PageNotFound}></Route>
      {/* <Route exact path='/dashboard' component={Dashboard}></Route> 
        <Route exact path='/about' component={About}></Route>
        <Route exact path='/contact' component={Contact}></Route> */}
    </Switch>
  );
};

export default Router;
