import Userfront from "@userfront/react";
import { Redirect } from "react-router-dom";

const CheckAuth = ({ location }) => {
  if (!Userfront.accessToken()) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }

  return <div></div>;
};
export default CheckAuth;
