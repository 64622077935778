import Userfront from "@userfront/react";

Userfront.init("5nxrwvn7");

const SignupForm = Userfront.build({
  toolId: "knrllr",
});

const A = () => {
  return <SignupForm />;
};

export default A;
